<template>
    <fieldset class="elevation-1 rounded">
        <slot name="label">
            <legend v-if="label" class="px-1 mb-0 font-weight-medium">
                {{ label }}
                <span v-if="required" class="error--text">*</span>
            </legend>
        </slot>
        <div class="px-3">
            <slot name="content"></slot>
        </div>
    </fieldset>
</template>

<script>
export default {
    name: 'FieldsetGroup',
    props: {
        label: {
            type: String,
            'default': ''
        },
        required: {
            type: Boolean,
            'default': false
        }
    }
};
</script>

<style scoped lang="scss">
    fieldset {
        border: none;
    }
</style>
