<template>
    <Modal :modal-title="title"
           :dialog="positionFormVisibility"
           max-width="1600px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <PositionForm ref="form"
                          v-if="positionFormVisibility"
                          :is-editable="isEditable">

            </PositionForm>

            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import PositionForm from '@/components/positions/PositionForm';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManagePosition',
    components: { Modal, PositionForm, ValidationError },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapState('managePosition', [
            'positionFormVisibility',
            'positionData',
            'editedPositionId'
        ]),
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        title () {
            return this.isEditable ? this.$t('buttons.edit') : this.$t('buttons.add');
        },
        isEditable () {
            return this.editedPositionId !== null && this.editedPositionId !== undefined;
        }
    },
    methods: {
        close () {
            this.$store.commit('managePosition/SET_POSITION_FORM_VISIBILITY', false);
            this.$refs.form.resetValidation();
            // prevent showing bad data 0.1s
            setTimeout(() => {
                this.$store.commit('managePosition/SET_POSITION_ID', null);
                this.$store.commit('managePosition/RESET_POSITION_DATA');
            }, 100);
            this.$emit('fetch');
        },
        visibility (event) {
            this.$store.commit('managePosition/SET_POSITION_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        createPosition () {
            this.loader = true;
            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.loader = false;
                        return this.$store.dispatch('managePosition/createPosition')
                            .then(() => {
                                this.close();
                            })
                            .finally(() => {
                                this.$emit('fetch');
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updatePosition () {
            this.loader = true;
            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.loader = false;
                        return this.$store.dispatch('managePosition/updatePosition')
                            .then(() => {
                                this.close();
                            })
                            .finally(() => {
                                this.$emit('fetch');
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEditable) {
                this.updatePosition();
            } else {
                this.createPosition();
            }
        }
    }
};
</script>

<style scoped>

</style>
