<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
            <ValidationProvider :name="label"
                                :vid="name"
                                :rules="rules"
                                v-slot="{ errors }">
                <v-text-field
                    v-bind="$attrs"
                    v-model="color"
                    outlined
                    autocomplete="off"
                    :error-messages="errors"
                    :class="className"
                    readonly
                    ref="field"
                    v-on="on">
                    <template slot="label">
                        {{ label }}
                        <span v-if="isRequired" class="error--text">*</span>
                    </template>
                    <template slot="prepend-inner"
                              v-if="$attrs.value">
                        <span class="color-example clickable"
                              @click="menu = true"
                              :style="{'--example-color': $attrs.value}"></span>
                    </template>
                    <template slot="append"
                              v-if="$attrs.value">
                        <span class="d-inline-block"
                              @click="clearColor">
                            <v-icon size="18"
                                    class="clickable">{{ ICONS.CLOSE }}</v-icon>
                        </span>
                    </template>
                </v-text-field>
            </ValidationProvider>
        </template>

        <v-color-picker v-bind="$attrs"
                        v-model="color"
                        v-on="$listeners"></v-color-picker>
    </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { ICONS } from '@/constants/icons';
import isFieldRequired from '@/mixins/isFieldRequired';

export default {
    name: 'ColorPicker',
    mixins: [isFieldRequired],
    components: { ValidationProvider },
    props: {
        label: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        className: {
            type: [String, Array, Object],
            required: false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        }
    },
    data: () => ({
        menu: false,
        ICONS,
        color: ''
    }),
    watch: {
        '$attrs.value': {
            handler (val) {
                this.color = val;
            },
            immediate: true
        },
        color: {
            handler (val) {
                if (val !== this.$attrs.value) {
                    const res = typeof this.color === 'object' ? '' : this.color;
                    this.$emit('input', res);
                }
            }
        }
    },
    methods: {
        clearColor () {
            this.$refs.field.blur();
            this.color = null;
        }
    }
};
</script>

<style scoped lang="scss">
    .color-example {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: var(--example-color);
    }

    ::v-deep {
        & .v-input__prepend-inner {
            margin-top: 0;
            align-self: center;
        }
        & .v-label--active {
            left: -18px !important;
        }
    }
</style>
