<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs">
            <v-layout align-stretch
                      :wrap="isMobile">
                <v-flex class="px-4">
                                        <TextField :label="$t('labels.name')"
                               v-model="name"
                               rules="required"></TextField>
                    <TextField :label="$t('labels.completion_percent')"
                               v-model="completion_percent"
                               :rules="{ required: true, between: [0,100] }"></TextField>
                    <ColorPicker :label="$t('labels.color')"
                                 v-model="color"
                                 @clear="color = $event"
                                 rules="required"></ColorPicker>
                    <RadioGroup v-model="is_default"
                                class="mt-1"
                                :options="defaultOptions"
                                :label="$t('labels.default_status')">
                    </RadioGroup>
                    <TextareaField :label="$t('labels.description')"
                                   name="description"
                                   :disabled="isEditable"
                                   v-model="description"></TextareaField>
                </v-flex>
            </v-layout>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { ICONS } from '@/constants/icons';
import TextareaField from '@/components/widgets/forms/TextareaField';
import TextField from '@/components/widgets/forms/TextField';
import ColorPicker from '@/components/widgets/forms/ColorPicker';
import RadioGroup from '@/components/widgets/forms/RadioGroup';

export default {
    name: 'ManageIncidentStatusForm',
    components: { ValidationObserver, TextareaField, TextField, ColorPicker, RadioGroup },
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        ICONS
    }),
    computed: {
        ...mapFields('manageIncident', [
            'incidentStatusData.name',
            'incidentStatusData.description',
            'incidentStatusData.color',
            'incidentStatusData.completion_percent',
            'incidentStatusData.is_default',
            'incidentStatusData.description_for_mail',
            'incidentStatusFormVisibility'
        ]),
        ...mapState('manageIncident', [
            'incidentStatusData'
        ]),
        defaultOptions () {
            return [
                {
                    text: this.$t('answers.yes'),
                    value: 1
                },
                {
                    text: this.$t('answers.no'),
                    value: 0
                }
            ];
        },
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        showSiteSuccessMessage () {
            return this.customerId ? '' : this.$t('messages.choose_customer');
        },
        showLocationSuccessMessage () {
            return this.siteId ? '' : this.$t('messages.choose_site');
        },
        showStatusSuccessMessage () {
            return this.type ? '' : this.$t('messages.choose_incident_type');
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    ::v-deep {
        & .v-input__icon--clear {
            & button {
                &::before {
                    font-size: 16px;
                }
            }
        }

        & .v-input--is-disabled {
            & .v-messages__message {
                font-size: $xxs;
            }
        }
    }
</style>
