<template>
    <ValidationProvider :name="$attrs.label"
                        :vid="name"
                        :rules="rules"
                        v-slot="{ errors }">
        <v-select outlined
                  :error-messages="errors"
                  :class="className"
                  v-bind="$attrs"
                  v-on="$listeners">
            <template v-if="$attrs.label" slot="label">
                {{ $attrs.label }}
                <span v-if="isRequired && !hideRequiredMark" class="error--text">*</span>
            </template>
        </v-select>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';

export default {
    name: 'SelectField',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        name: {
            type: String,
            'default': ''
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        hideRequiredMark: {
            type: Boolean,
            'default': false
        },
        className: {
            type: [String, Array, Object],
            required: false
        },
        defaultValue: {
            type: Object,
            required: false
        }
    },
    mounted () {
        if (!this.$attrs.value && this.defaultValue?.value) {
            this.$emit('input', this.defaultValue.value);
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-input__icon--clear {
            & button {
                &::before {
                    font-size: 16px;
                }
            }
        }
    }
</style>
