<template>
    <v-sheet color="white"
             min-height="80vh"
             class="px-0 py-0"
             elevation="0">
        <ValidationObserver ref="obs">
            <v-container>
                <v-row>
                    <v-col cols="12" lg="3">
                        <TextField :label="$t('labels.name')"
                                   v-model="name"
                                   rules="required"></TextField>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <SelectField :label="$t('labels.role')"
                                     @select="updateRole($event)"
                                     @change="updateRole($event)"
                                     :value="currentRole"
                                     :items="roleOptions"
                                     rules="required"></SelectField>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <TextField :label="$t('labels.configuration')"
                                   v-model="config"></TextField>
                    </v-col>
                    <v-col cols="12">
                        <v-flex v-if="currentRole !== 'is_manager'">
                            <h3>{{ $t('labels.access_rights')}}</h3>
                            <v-layout class="mb-3" justify-end>
                                <v-btn @click="checkAllPermissions()"
                                       className='my-0'>{{$t('labels.select_all')}}</v-btn>
                                <v-btn @click="unCheckAllPermissions()"
                                       className='my-0'>{{$t('buttons.remove_selected')}}</v-btn>
                            </v-layout>
                        </v-flex>
                        <v-divider></v-divider>

                        <div v-if="currentRole === 'is_manager'">
                            <v-tabs
                                :dark="false"
                                :icons-and-text="false"
                                :grow="false" >
                                <v-tab v-for="(tab, index) in adminTabs" :key="index">
                                    <v-icon top class="mr-2">
                                        {{ tab.icon }}
                                    </v-icon>
                                    {{ tab.title }}
                                </v-tab>
                                <v-tab-item>
                                    <p class="py-5">{{ $t('permissions.you_have_all_permissions') }}</p>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup>
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in mobile"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </div>

                        <div v-if="currentRole !== 'is_manager'">
                            <v-tabs
                                    :dark="false"
                                    :icons-and-text="false"
                                    :grow="false" >
                                <v-tab v-for="(tab, index) in tabs" :key="index">
                                    <v-icon top class="mr-2">
                                        {{ tab.icon }}
                                    </v-icon>
                                    {{ tab.title }}
                                </v-tab>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('labels.customers')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in customers"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('labels.sites')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in places"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('labels.locations')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in points"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('labels.templates')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in templates"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('navigation.employees')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in employees"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('navigation.subcontractors')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in subcontractors"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('navigation.menu')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in view"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text.toLowerCase()}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup :label="$t('labels.reports')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in reports"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('navigation.schedule')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in schedule"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="3">
                                            <FieldsetGroup :label="$t('navigation.inspections')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in inspections"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup :label="$t('navigation.incidents_types_reports')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in incidentsKinds"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup :label="$t('navigation.statuses')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in incidentsStatuses"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup :label="$t('navigation.incidents')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in incidents"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup :label="$t('labels.tracks')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in tracks"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup :label="$t('navigation.general')">
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in configuration"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    <v-col cols="12" xl="4">
                                        <FieldsetGroup :label="$t('navigation.settings')">
                                            <template slot="content">
                                                <Checkbox v-model="position_permissions[item.text]"
                                                        className='my-0'
                                                        v-for="(item, i) in settings"
                                                        :key="i"
                                                        :label="$t(`permissions.${item.text}`)"></Checkbox>
                                            </template>
                                        </FieldsetGroup>
                                    </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" xl="4">
                                            <FieldsetGroup>
                                                <template slot="content">
                                                    <Checkbox v-model="position_permissions[item.text]"
                                                            className='my-0'
                                                            v-for="(item, i) in mobile"
                                                            :key="i"
                                                            :label="$t(`permissions.${item.text}`)"></Checkbox>
                                                </template>
                                            </FieldsetGroup>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </div>
                    </v-col>
                </v-row>

            </v-container>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import { ICONS } from '@/constants/icons';
import TextField from '@/components/widgets/forms/TextField';
import SelectField from '@/components/widgets/forms/SelectField';
import FieldsetGroup from '@/components/widgets/forms/FieldsetGroup';
import Checkbox from '@/components/widgets/forms/Checkbox';
import permissionsRules from '@/mixins/permissionsRules';

export default {
    name: 'PositionForm',
    components: { ValidationObserver, TextField, SelectField, FieldsetGroup, Checkbox },
    mixins: [permissionsRules],
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        ICONS,
        role: null
    }),
    computed: {
        allGroups () {
            return [...new Set(this.permissions.map(e => e.group))];
        },
        customers () {
            return this.permissions.filter(p => p.group === 'Customer');
        },
        places () {
            return this.permissions.filter(p => p.group === 'Place');
        },
        employees () {
            return this.permissions.filter(p => p.group === 'Employee');
        },
        subcontractors () {
            return this.permissions.filter(p => p.group === 'Company');
        },
        view () {
            return this.permissions.filter(p => p.group === 'View');
        },
        incidents () {
            return this.permissions.filter(p => p.group === 'Incident');
        },
        tracks () {
            return this.permissions.filter(p => p.group === 'Track');
        },
        incidentsStatuses () {
            return this.permissions.filter(p => p.group === 'Incident status');
        },
        incidentsKinds () {
            return this.permissions.filter(p => p.group === 'Incident kind');
        },
        schedule () {
            return this.permissions.filter(p => p.group === 'Inspection schedule');
        },
        points () {
            return this.permissions.filter(p => p.group === 'Point');
        },
        templates () {
            return this.permissions.filter(p => p.group === 'Template');
        },
        inspections () {
            return this.permissions.filter(p => p.group === 'Inspection');
        },
        reports () {
            return this.permissions.filter(p => p.group === 'Custom reports');
        },
        settings () {
            return this.permissions.filter(p => p.group === 'Settings');
        },
        mobile () {
            return this.permissions.filter(p => p.group === 'Mobile');
        },
        configuration () {
            return this.permissions.filter(p => p.group === 'Configuration').sort((a, b) => a.value - b.value);
        },
        tabs () {
            return [
                { title: this.$t('navigation.general'), icon: ICONS.HOME },
                { title: this.$t('navigation.reports'), icon: ICONS.CHART },
                { title: this.$t('navigation.inspections'), icon: ICONS.CHECKBOX_MARKED },
                { title: this.$t('navigation.incidents'), icon: ICONS.ALERT },
                { title: this.$t('navigation.tracks'), icon: ICONS.TRACKS },
                { title: this.$t('labels.configuration'), icon: ICONS.CONFIGURATION },
                { title: this.$t('labels.mobile_app'), icon: ICONS.CELLPHONE }

            ];
        },
        adminTabs () {
            return [
                { title: this.$t('navigation.general'), icon: ICONS.HOME },
                { title: this.$t('labels.mobile_app'), icon: ICONS.CELLPHONE }
            ];
        },
        ...mapFields('managePosition', [
            'positionFormVisibility',
            'permissions',
            'positionData',
            'positionData.name',
            'positionData.position_permissions',
            'positionData.is_client',
            'positionData.is_manager',
            'positionData.is_inspector',
            'positionData.config'
        ]),
        roleOptions () {
            return [
                {
                    text: this.$t('labels.customer'),
                    value: 'is_client',
                    hook: parseInt(this.is_client)
                },
                {
                    text: this.$t('labels.manager'),
                    value: 'is_manager',
                    hook: parseInt(this.is_manager)
                },
                {
                    text: this.$t('navigation.inspector'),
                    value: 'is_inspector',
                    hook: parseInt(this.is_inspector)
                }
            ];
        },
        currentRole () {
            const arr = this.roleOptions;
            return arr.length && arr.find(function (e) {
                return e.hook === 1;
            })?.value;
        },
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    methods: {
        updateRole (val) {
            switch (val) {
            case 'is_client':
                this.is_client = 1;
                this.is_manager = 0;
                this.is_inspector = 0;
                break;
            case 'is_manager':
                this.is_client = 0;
                this.is_manager = 1;
                this.is_inspector = 0;
                break;
            case 'is_inspector':
                this.is_client = 0;
                this.is_manager = 0;
                this.is_inspector = 1;
                break;
            }
        },
        resetValidation () {
            return this.$refs.obs.reset();
        },
        checkAllPermissions () {
            this.permissions.forEach(e => {
                // clear all undefined
                this.position_permissions[e.text] = false;
                this.position_permissions[e.text] = true;
            });
        },
        unCheckAllPermissions () {
            this.permissions.forEach(e => {
                this.position_permissions[e.text] = true;
                this.position_permissions[e.text] = false;
            });
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>
