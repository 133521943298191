import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=254621b7&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./ColorPicker.vue?vue&type=script&lang=js&"
export * from "./ColorPicker.vue?vue&type=script&lang=js&"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=254621b7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254621b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VColorPicker,VIcon,VMenu,VTextField})
