<template>
    <div :class="className">
        <p class="v-label mb-1 px-1 font-weight-medium">{{ label }}
            <span v-if="isRequired" class="error--text">*</span></p>
        <ValidationProvider :name="validationName"
                            :vid="name"
                            :rules="rules"
                            v-slot="{ errors }">
            <v-radio-group v-model="innerValue"
                           class="px-2"
                           :error-messages="errors">
                <v-radio v-for="(item, i) in options"
                         :key="i"
                         :label="item.text"
                         :value="item.value"></v-radio>
            </v-radio-group>
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';

export default {
    name: 'RadioGroup',
    mixins: [isFieldRequired],
    components: { ValidationProvider },
    props: {
        label: {
            type: String,
            'default': ''
        },
        value: {
            type: null
        },
        name: {
            type: String,
            'default': ''
        },
        options: {
            type: Array,
            required: true
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        alternativeValidationName: {
            type: String,
            'default': ''
        },
        withoutStyles: {
            type: Boolean,
            'default': false
        },
        className: {
            type: [String, Array, Object],
            required: false,
            'default': 'elevation-1 rounded mb-4'
        }
    },
    data: () => ({
        innerValue: ''
    }),
    computed: {
        validationName () {
            return this.alternativeValidationName || this.label;
        }
    },
    watch: {
        innerValue (newVal) {
            this.$emit('input', newVal);
        },
        value: {
            handler (newVal) {
                this.innerValue = newVal;
            },
            immediate: true
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-input--radio-group {
            & .v-messages__message {
                margin-bottom: 5px;
            }
        }
    }
</style>
