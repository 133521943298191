<template>
    <v-layout align-center>
        <span class="type__color mr-2"
              :style="{'background-color': color}"></span>
        <span>{{ type.name }}</span>
    </v-layout>
</template>

<script>
export default {
    name: 'Type',
    props: {
        type: {
            type: Object,
            required: true
        }
    },
    computed: {
        color () {
            return this.type.color || '#000000';
        }
    }
};
</script>

<style scoped lang="scss">
    .type {
        &__color {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
            flex-shrink: 0;
        }
    }
</style>
