export default {
    watch: {
        'position_permissions.destroy_customer': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_customer': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_customer': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_point': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_point': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.update_place = true;
                    this.position_permissions.read_point = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_point': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                }
            },
            immediate: true
        },
        'position_permissions.read_place': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_place': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.update_customer = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_place': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_place': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_employee': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_company = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_employee': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_company = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_employee': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_employee = true;
                }
            },
            immediate: true
        },
        'position_permissions.change_password_employee': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_employee = true;
                    this.position_permissions.update_employee = true;
                    this.position_permissions.read_company = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_company': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_company = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_company': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_company = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_company': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_company = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_template': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_template = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_template': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_template = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_template': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_template = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_incident_kind': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_incident_status = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_incident_kind': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_incident_status = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_incident_kind': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_kind = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_incident_status': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_status = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_incident_status': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_status = true;
                    this.position_permissions.read_incident_kind = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_incident_status': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_status = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_incident': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_incident = true;
                    this.position_permissions.read_incident_status = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_incident': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident = true;
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_incident_status = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_incident': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_inspection_schedule': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_inspection_schedule = true;
                    this.position_permissions.read_incident = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_inspection_schedule': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_inspection_schedule = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_inspection_schedule': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_inspection_schedule = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_inspection': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_inspection = true;
                }
            },
            immediate: true
        },
        'position_permissions.read_configuration': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_incident_status = true;
                    this.position_permissions.read_inspection = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_configuration': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.update_incident_kind = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_inspection = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.update_template = true;
                    this.position_permissions.read_configuration = true;
                    this.position_permissions.update_configuration = true;
                    this.position_permissions.read_incident_status = true;
                    this.position_permissions.update_incident_status = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_configuration': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.add_incident_kind = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.add_template = true;
                    this.position_permissions.read_configuration = true;
                    this.position_permissions.add_configuration = true;
                    this.position_permissions.read_incident_status = true;
                    this.position_permissions.add_incident_status = true;
                    this.position_permissions.read_inspection = true;
                    this.position_permissions.update_incident_kind = true;
                    this.position_permissions.update_template = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_configuration': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_incident_kind = true;
                    this.position_permissions.destroy_incident_kind = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.destroy_template = true;
                    this.position_permissions.read_configuration = true;
                    this.position_permissions.read_incident_status = true;
                    this.position_permissions.destroy_incident_status = true;
                    this.position_permissions.read_inspection = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_settings': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_settings = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_settings': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_settings = true;
                }
            },
            immediate: true
        },
        'position_permissions.system_logs': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_settings = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_settings': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_settings = true;
                }
            },
            immediate: true
        },
        'position_permissions.add_track': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_track = true;
                    this.position_permissions.read_incident = true;
                }
            },
            immediate: true
        },
        'position_permissions.update_track': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_customer = true;
                    this.position_permissions.read_place = true;
                    this.position_permissions.read_point = true;
                    this.position_permissions.read_template = true;
                    this.position_permissions.read_employee = true;
                    this.position_permissions.read_track = true;
                    this.position_permissions.read_incident = true;
                }
            },
            immediate: true
        },
        'position_permissions.destroy_track': {
            handler (val) {
                if (val === true) {
                    this.position_permissions.read_track = true;
                }
            },
            immediate: true
        }
    }
};
