<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.positions') }}</h1>
        <FiltersBar :withFilters="false"
                    ref="filtersBar"></FiltersBar>

        <PositionsTable @change-page="changePage($event)"
                       @change-amount="changeAmount($event)"
                       @sort="sortingColumn = $event"
                       @fetch="fetchPositions"
                       :loader="loader"
                       :changed-page="pagination.page"></PositionsTable>

        <ManagePosition @fetch="fetchPositions"></ManagePosition>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import PositionsTable from '@/components/positions/PositionsTable';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import ManagePosition from '@/components/positions/ManagePosition';

export default {
    name: 'Positions',
    components: { PositionsTable, FiltersBar, ManagePosition },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20
        },
        loader: false,
        sortingColumn: null
    }),
    computed: {
        ...mapFields('search', [
            'search'
        ])
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchPositions();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.fetchPositions();
                }
            }
        }
    },
    methods: {
        fetchPositions () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[name][prefix]'] = this.search;
            }

            this.$store.dispatch('positions/getPositions', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchPositions();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchPositions();
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.POSITION)) {
                vm.fetchPositions();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
